<template>
  <div class="kpi-container">
    <div class="kpi-row">
      <div
        class="kpi-card kpi-card-light d-flex flex-row align-items-center justify-content-between"
        style="padding: 1.2143rem"
      >
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div style="margin-left: -17px; overflow: hidden">
            <b-icon
              shift-h="-2"
              icon="ui-checks"
              font-scale="5"
              class="text-primary"
              style="opacity: 0.5"
            />
          </div>
          <div
            style="font-weight: 600"
          >
            Total de questionários preenchidos na plataforma
          </div>
        </div>
        <div
          style="font-weight: 600; font-size: 2.2857rem"
        >
          {{ totalQuizzes }}
        </div>
      </div>
      <div
        class="kpi-card kpi-card-primary d-flex flex-row align-items-center justify-content-between"
        style="padding: 1.2143rem"
      >
        <div style="margin-left: -17px; margin-top: -19px;   overflow: hidden; position: absolute; border-radius: 25px">
          <b-iconstack
            font-scale="6"
            shift-h="-5"
            shift-v="5"
          >
            <b-icon
              stacked
              icon="circle-fill"
              class="text-primary"
              style="opacity: 0.5"
              font-scale="1"
            />
            <b-icon
              stacked
              icon="circle-fill"
              class="text-primary-hover"
              scale="0.5"
            />
          </b-iconstack>
        </div>
        <div
          class="text-light"
          style="font-weight: 600; z-index: 5"
        >
          Estado de maturidade digital
        </div>
        <div class="buttons m-0">
          <div
            class="button md secondary"
            @click="() => {
              if(openQuiz) {
                goToQuiz(openQuiz)
              } else {
                startQuiz()
              }
            }"
          >
            {{ openQuiz ? 'Continuar a responder' : 'Iniciar questionário' }}
            <b-icon
              class="ml-2"
              icon="arrow-right-circle"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="totalQuizzes > 0"
      class="kpi-row"
    >
      <div
        class="kpi-card kpi-card-light"
      >
        <div
          style="
            background-color: #00455F;
            min-height: 6.8571rem;
            border-top-left-radius: 0.5714rem;
            border-top-right-radius: 0.5714rem;
            padding: 0.5714rem;
            padding-left: 2rem;
            padding-right: 2rem;
          "
          class="align-items-center justify-content-between d-flex flex-row"
        >
          <div
            class="text-light d-flex flex-row align-items-center flex-wrap"
            style="font-weight: 600"
          >
            <div style="margin-right: 2.5rem">
              Diagnósticos
            </div>
            <div
              v-for="quiz in quizzes.filter(el => el.quiz.status === 'submitted')"
              :key="quiz.quiz.id"
              class="buttons m-0"
            >
              <div
                style="margin-right: 2rem; padding: 0.8571rem; padding-top: 0.6429rem; padding-bottom: 0.6429rem; cursor: pointer"
                :class="active === quiz.quiz.id ? 'button primary' : 'button transparent'"
                @click="() => {
                  active = quiz.quiz.id
                }"
              >
                <div
                  style="font-size: 1.8571rem; font-weight: 600;  margin: 0; padding: 0; line-height: 1.8571rem; margin-bottom: 0.5rem"
                  :style="active === quiz.quiz.id ? 'opacity: 1;' : 'opacity: 0.8;'"
                >
                  {{ quiz.quiz.created_at.split(" ")[0].split("-")[2] }}
                </div>
                <div
                  style="font-size: 1rem; font-weight: 400;  margin: 0; padding: 0; line-height: 1.1rem"
                  :style="active === quiz.quiz.id ? 'opacity: 1;' : 'opacity: 0.8;'"
                >
                  {{ months[parseInt(quiz.quiz.created_at.split(" ")[0].split("-")[1])] }}
                </div>
                <div
                  style="font-size: 1rem; font-weight: 400;  margin: 0; padding: 0; line-height: 1.1rem"
                  :style="active === quiz.quiz.id ? 'opacity: 1;' : 'opacity: 0.8;'"
                >
                  {{ quiz.quiz.created_at.split(" ")[0].split("-")[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div
              class="button primary"
              @click="() => {
                if(quizzes.find(el => el.quiz.id === active)) {
                  download(quizzes.find(el => el.quiz.id === active).quiz.pdf)
                }
              }"
            >
              Exportar relatório
              <b-icon
                class="ml-2"
                icon="download"
              />
            </div>
          </div>
        </div>
        <div
          style="background-color: light; min-height: 6.8571rem; padding: 1.7857rem;"
          class="d-flex"
        >
          <b-overlay
            :show="!loaded"
            opacity="0.5"
            style="width: 100%"
            class="d-flex"
          >
            <div
              v-if="report && loaded"
              class="d-flex flex-column flex-wrap text-secondary"
              style="font-weight: 600; flex: 1; padding: 3.6428rem;"
            >
              <div
                v-if=" report.quiz[0].facilitator && report.quiz[0].facilitator.state == 2"
                class="d-flex  flex-row align-items-center flex-wrap"
                style="margin-bottom: 24px"
              >
                <div
                  class="bg-primary d-flex align-items-center justify-content-center"
                  style="width: 3.4286rem; height: 3.4286rem; border-radius: 0.1429rem; margin-right: 1.0714rem"
                >
                  <b-icon
                    icon="person-check-fill"
                    style="color: white"
                    scale="1.5"
                  />
                </div>
                <div>
                  <div style="font-size: 16px; font-weight: 600">
                    Facilitador: {{ report.quiz[0].facilitator.facilitator.user.name }}
                  </div>
                  <div style="font-size: 14px; font-weight: 600">
                    {{ report.quiz[0].facilitator.facilitator.user.email }}
                  </div>
                </div>
              </div>
              <div class=" d-flex flex-row align-items-center flex-wrap">
                <div
                  class="text-primary"
                  style="font-weight: 600; margin-right: 1.7857rem"
                >
                  Nível Atual
                  <div style="font-size: 2.8571rem">
                    {{ Math.floor(report.global[0].classification) }}
                  </div>
                </div>
                <div
                  class="text-primary"
                  style="font-weight: 600"
                >
                  Nível Futuro
                  <div style="font-size: 2.8571rem">
                    {{ Math.floor(futureScore) }}
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px; border-top: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Estratégia e Organização
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "1").classification }}
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px;  border-top: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Infraestrutura Tecnológica
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "2").classification }}
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px;  border-top: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Operações Inteligentes
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "3").classification }}
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px;  border-top: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Produtos Inteligentes
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "4").classification }}
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px;  border-top: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Serviços Baseados em Dados
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "5").classification }}
                </div>
              </div>
              <div
                class="d-flex flex-row justify-content-between"
                style="padding-top: 0.8571rem; padding-bottom: 0.8571rem; font-size: 13px;  border-top: 1px solid #E0E0E0;  border-bottom: 1px solid #E0E0E0"
              >
                <div style="font-weight: 600; ">
                  Recursos Humanos
                </div>
                <div style="font-weight: 800">
                  {{ report.dimensions.find(el => el.code === "6").classification }}
                </div>
              </div>
            </div>
            <div
              v-if="svg && report && future && loaded"
              class="d-flex flex-column flex-wrap text-secondary"
              style="font-weight: 600; flex: 2; padding: 3.6428rem"
            >
              <div
                style="width: 60%"
                v-html="svg"
              />

              <popover-kpi
                v-for="(dimension, i) in report.dimensions"
                :key="`popover-dimension-${i+1}`"
                :target="`popover-dimension-${i+1}`"
                :report="report"
                :future="future"
              />
              <popover-kpi
                v-for="(theme, i) in report.themes"
                :key="`popover-theme-${i+1}`"
                :target="`popover-theme-${i+1}`"
                :report="report"
                :future="future"
              />
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from '../../../services/api';
import { generateChartSite } from '../../../services/common/generate-chart-site';
import PopoverKPI from './PopoverKPI.vue';

export default {
  name: 'DashboardKPICompany',
  components: { 'popover-kpi': PopoverKPI },
  data() {
    return {
      quizzes: [],
      openQuiz: null,
      active: null,
      report: null,
      future: null,
      futureScore: null,
      totalQuizzes: 0,
      svg: null,
      loaded: false,
      months: {
        1: 'JANEIRO',
        2: 'FEVEREIRO',
        3: 'MARÇO',
        4: 'ABRIL',
        5: 'MAIO',
        6: 'JUNHO',
        7: 'JULHO',
        8: 'AGOSTO',
        9: 'SETEMBRO',
        10: 'OUTUBRO',
        11: 'NOVEMBRO',
        12: 'DEZEMBRO',
      },
    };
  },
  watch: {
    active: {
      async handler(value) {
        this.loaded = false;
        await get(`score/${value}`, {})
          .then((response) => {
            this.report = response.data;
            this.svg = generateChartSite(this.report.themes.map((el) => el.classification), this.report.dimensions.map((el) => el.classification), this.report.global, this.future, this.report.kos).innerHTML;
            this.loaded = true;
          });
      },

    },
  },
  async created() {
    await get('quiz', {})
      .then((response) => {
        if (response.data) {
          this.quizzes = response.data.filter((el) => el.quiz.status === 'submitted').reverse();
          if (response.data.find((el) => el.quiz.status === 'open' || el.quiz.status === 'opened')) {
            this.openQuiz = response.data.find((el) => el.quiz.status === 'open' || el.quiz.status === 'opened').quiz.id;
          }
          if (this.quizzes.length > 0) {
            this.active = this.quizzes[this.quizzes.length - 1].quiz.id;
            this.future = this.quizzes[this.quizzes.length - 1].future;
            this.futureScore = this.quizzes[this.quizzes.length - 1].future_score;
            for (let i = 0; i <= response.data[0].total_quizzes_platform; i += 1) {
              setTimeout(() => {
                this.totalQuizzes = i;
              }, 0.5);
            }
          }
        }
      });
  },
  methods: {
    goToQuiz(id) {
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({ name: 'quiz' });
    },
    startQuiz() {
      post('quiz', {})
        .then(async (response) => {
          await post(`quiz/company/${response.data.id}`, {})
            .then(() => {
              this.goToCompanyQuestions(response.data.id);
            });
        });
    },
    download(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement('a');
      const fileName = 'RelatórioI4.0.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    goToCompanyQuestions(id) {
      this.$store.commit('setDisabled', false);
      this.$store.commit('setQuiz', id);
      this.$router.push({
        name: 'company_quiz',
        params: {
          id,
        },
      });
    },
  },
};
</script>
